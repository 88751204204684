@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap');

html, body {
  padding: 0;
  margin: 0;
  font-family: "Brioni Sans Light"

}

h1, h2, h3, h4, h5, h6 {
    font-family: "Brioni Light";
    }
h3.cms_module_title {
  font-size: 28px;
  margin-bottom: 30px;
}

.fixed_header {
  position: fixed;
  z-index: 300;
  width: 100%;
  background: white;
  height: 100px;
}
.scrollable_content {
  padding-top: 100px;
}


.above_header {
  width: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo_div {
    display: flex;
    align-items: center;
  }
  .header_logo {
    max-height: 90px;
  }
  .search_icon {
    padding: 20px;
  }

 #header-search {
    text-align: right;
    height: 40px;
    .search_form {
      background-color: $gray-100;
      height: 40px;
      display: flex;
      align-items: center;
    }
    .header-search_input {
      height: 40px;
      max-width: 268px;
      font-size: 18px;
      color: #231f20;
      border: 0;
      background-color: transparent;
      margin: 0;
      padding: 5px 5px 5px 15px;
      &::placeholder {
        color: #231f20;
      }
    }
    .header-search_input:focus + .input-group-btn {
      display: none;
    }
    .header-search_input:active + .input-group-btn {
      display: none;
    }
  }

}
.menu_text {
  @media (max-width: 780px) {
    display: none;
  }
}

a:target {
  margin-top: -200px !important;
  padding-top: 200px !important;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: -160px !important;
    padding-top: 160px !important;
  }
}
html.logged-in a:target {
  margin-top: -250px !important;
  padding-top: 250px !important;
  @media (max-width: map-get($grid-breakpoints, xl)) {
    margin-top: -210px !important;
    padding-top: 210px !important;
  }
}

.portlet_nav .cms_module_title {
  display: none;
}


//sidepanel
.sidepanel  {
  width: 0;
  position: fixed;
  z-index: 9000;
  max-height: auto;
  right: 0;
  background-color: #111;
  //overflow-x: hidden;
  overflow-y: auto;
  transition-duration: 0.3s;
  padding-top: 15px;
}

@media (min-width: 0px) and (max-width: 991px) {
    .sidepanel {
      height: 100%;
      overflow: auto;
      padding-bottom: 100px;
  }
}

#area_header a {
  text-decoration: none;
  color: #818181;
  display: block;
  transition-duration: 0.1s;
}

.btn_container {
    background-color: theme-color("primary");
    height: 40px;
    width: 40px;
    //border-radius: 2px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.btn_container img {
    height: 16px;
    width: 16px;
    margin: 12px;
}

.sidepanel a:hover {
  //color: #f1f1f1;
   text-decoration: none;
}

.sidepanel .closebtn {
  text-align: center;
  font-size: 26px;
}

.openbtn {
  font-size: 26px;
  cursor: pointer;
  background-color: theme-color("primary");
  color: white;
  border: none;
  height: 40px;
  width: 40px;
}

.openbtn:hover {
  background-color:#444;
}

.above_header .search img {
  padding: 15px;
}

.above_header, .above_header > .row{
  height: 100px;
}

.above_header .menu {
  display: flex;
  align-items: center;
  justify-content: right;
}

.sidepanel .language span.w3c-lang-name {
    display: inline-block !important;
    border: 1px solid #6a6a6a;
    color: #231f20;
    font-weight: bold;
    //border-radius: 2px;
    padding: 7px;
    width: 32px;
    height: 32px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    margin: 3px;
    text-transform: uppercase;
    @extend .rounded;
}
.current_lang {
    display: inline-block !important;
}

.sidepanel .language .current_lang span.w3c-lang-name {
    color: theme-color("primary") !important;
    border: 1px solid theme-color("primary") !important;
}

.full-lang-name {
    display: none !important;
}

.sidepanel .login {
  display: inline-block;
  float: right;
  margin-top: 20px;
  background-color: #6a6a6a;
  width: 137px;
  height: 40px;
  text-align: center;
  //border-radius: 2px;

}
.sidepanel .login a {
  color: #ffffff !important;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.1px;
  position: relative;
  top: 8px;
}
.menu_text {
    margin-right: 20px;
}
////sidepanel

.sidepanel .row .col-12 .login {
  @media (max-width: 450px) {
    display: block !important;
    margin-top: 0;
    float: none;

  }
}
.sidebar.top {

  width: 50%;

  li.nav-item {
    white-space: nowrap;
  }

  .nav-link {
    display: inline-block;
    color: black;
    font-weight: normal;
    white-space: normal;
  }

  .onhover {
    color: #f9f9f9;
    background-color: #6a6a6a;
    a {
      color: #f9f9f9;
    }

  }
  .submenu1 {
    position: absolute;
    min-height: 100%;
    display: block;
    right: 0;
    top: 0;
    width: 50%;
    color: #f9f9f9;
    background: #6a6a6a;
    z-index: 9999;
    a {
      color: #f9f9f9;
    }
    .collapse-link, .collapse-nochildren {
      display: none !important;
    }
  }

  .collapse-link, .collapse-nochildren {
    color: #6a6a6a;
    font-size: 20px;
    text-decoration: none;
    display: inline-block;
    width: 0px;
    padding: 0 5px;

    &::after {
      content: "\203A";
    }

    i {
      display: none;
    }
  }

  .collapse-link.collapsed {
    &::after {
      content:"\203A";
      color: $gray-400;
    }
  }

  .collapse-nochildren {
    &::after {
      content: " ";
    }
  }
}


.sidebar.side, .sidebar.mobile {

  margin-bottom: 50px;

  li.nav-item {
    white-space: nowrap;
  }
  .nav-link {
    display: inline-block;
    color: black;
    font-weight: 900;
    white-space: normal;
  }

  .submenu .nav-link {
    font-weight: 300;
    color: #6a6a6a;
  }

  .nav-link.current_page {
    color: theme-color("primary");
  }

  .collapse-link, .collapse-nochildren {
    color: #6a6a6a;
    font-size: 20px;
    text-decoration: none;
    display: inline-block;
    width: 20px;
    padding: 0 5px;

    &::after {
      content: "-";
    }

    i {
      display: none;
    }
  }

  .collapse-link.collapsed {
    &::after {
      content: "+";
    }
  }

  .collapse-nochildren {
    &::after {
      content: " ";
    }
  }


  .public_page.present_page {
    border-left: 2px solid green;
    border-right: none;
    border-bottom: none;
  }
  .public_page.not_present_page {
    border-left: 2px dotted green;
    border-right: none;
    border-bottom: none;
  }
  .private_page.present_page {
    border-left: 2px solid red;
    border-right: none;
    border-bottom: none;
  }
  .private_page.not_present_page {
    border-left: 2px dotted red;
    border-right: none;
    border-bottom: none;
  }
}


#area_onecol_silver:not(:empty)  {
  background: #f7f7f7;
}

#area_onecol_white:not(:empty)  {
  background: white;
}

#cms_area_side_portlets, #cms_area_middle, #cms_area_onecol_white, #cms_area_onecol_silver, #cms_area_silver_left, #cms_area_silver_right, #cms_area_white_left, #cms_area_white_right, #cms_area_side_menu {
  .cms_module {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.twocol_side {
  background: #f7f7f7;
}
.portlet_portfolio .portfolio_image {
  max-width: 250px;
  max-height: 250px;
}


.portlet_portfolio {
  table.portfolio td {
    padding: 8px;
  }
  tr:nth-child(odd) td {
    background: #f5f5f5;
  }

  td.data_label {
    font-weight: bold;
    text-align: right;
    padding-right: 24px;
  }
  .cms_module_title {
    display: none;
  }
  .admin_toolbox {
    margin-bottom: 30px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
    margin: 30px 0;
    border-bottom: 1px solid silver;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }

}

.portlet_search {
  .search_result {
    padding: 10px 0;
  }
  .resultsItem {
    padding: 3px 0;
    .icon {
      display: inline-block;
      margin-right: 10px;
    }
    .title {
      display: inline-block;
    }
  }
}

#unibox-suggest-box {
  a {
    padding: 10px;
  }
  .search_icon, .search_txt {
    display: inline-block;
    padding: 0;
    padding-right: 10px;
  }
  h4 {
    text-align: left;
    margin: 0 !important;
    padding: 10px;
    background: #f8f8f8;
  }

}


.portlet_imenik {
  .three_character_notice {
    padding: 15px;
  }
  #imenik_res {
    @extend .row;
    .panel {
      border: 1px solid silver;
      margin: 10px 0;
      padding: 15px;
    }
  }
}

.carousel_title {
  display: flex;
  align-items: center;
  background: theme-color("primary") !important;
  color: white;
  font-family: "Brioni Light";
  font-size: 48px;
  height: 200px;


  @media (max-width: 1200px) {
    font-size: 40px;
    height: 200px;
  }

  @media (max-width: 800px) {
    font-size: 32px;
    height: 160px;
  }
  @media (max-width: 576px) {
    font-size: 24px;
    height: 100px;
  }

}

.cms_module > a {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

.portlet_admin_newpage {
  margin-left: 30px;
  .form-group > label {
    width: 30%;
    text-align: right;
    padding-right: 25px;
  }
  label > input[type='checkbox'] {
    margin-top: 12px;
  }
  .input-group-addon {
    padding-top: 7px;
    padding-right: 5px;
  }
}

.portlet_admin_site label {
  width: 100%;
}

.portlet_repository {
  .head {
    position: relative;
    .arrow {
      position: static !important;
      height: 0 !important;
      &:after {
        cursor: pointer;
        position: absolute;
        width: 20px;
      }
    }
    &.closed {
      .arrow:after {
        content: "+" !important;
      }
    }

  }
  .cms_info_description {
    color: gray;
    font-style: italic;
  }
  i {
    font-style: normal;
  }
  i.info_author {
    display: none !important;
  }
}
html.logged-in .portlet_repository i.info_author {
    display:inline !important;
    font-size: 12px;
}

.portlet_carousel video {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;

}


.bodycms_twocol_left, .bodycms_twocol_right {
  .carousel {
    min-height: 400px;
    background-size: cover;
    margin: 0 !important;
    //background: url('../../../img/header.gif');
    background: white;

    padding: 0 !important;

    .carousel-item {
      height: 400px;
      position: relative;
    }

    .carousel-inner img {
      object-fit: cover;
      height: 400px;
      min-width: 100%;
    }
    .carousel-caption {
      width: 65%;
      text-align: left;
      color: white;
      left: 5%;
      bottom: 20%;

      p {
        color: white;
        font-family: "Brioni Sans Light";
        font-size: 24px;
        font-weight: 300;
        text-shadow: none;
        //background: rgba(35, 31, 32, .7);
        display: table-cell;
        padding: 10px;
      }

      a {
        color: white;
        text-decoration: none;
      }

      h1 {
        font-family: "Brioni Light";
        color: white;
        font-size: 48px;
        font-weight: 300;
        text-shadow: none;
        padding: 10px;
        display: inline-block;
        //background: rgba(35, 31, 32, .7);
        margin-bottom : 0px !important;

        a {
          color: white;
          text-decoration: none;
        }
      }
    }


    @media (max-width: 1200px) {
      min-height: 300px !important;
      max-height: 300px !important;
      .carousel-item {
        height: 300px;
        position: relative;
      }
      .carousel-inner img {
        height: 300px;
        min-width: 100%;
      }
      .carousel-caption {
        width: 70%;
        p {
          font-size: 20px;
          font-weight: 300;
        }
        h1 {
          font-size: 36px;
          a {
            font-size: 36px;
          }
        }
      }
    }

    @media (max-width: 800px) {
      min-height: 250px !important;
      max-height: 250px !important;
      .carousel-item {
        height: 250px;
        position: relative;
      }
      .carousel-inner img {
        height: 250px;
        min-width: 100%;
      }
      .carousel-caption {
        width: 70%;
        p {
          display: none;
        }
        h1 {
          font-size: 28px;
          a {
            font-size: 28px;
          }
        }
      }
    }
    @media (max-width: 576px) {
      min-height: 200px !important;
      max-height: 200px !important;
      .carousel-item {
        height: 200px;
        position: relative;
      }
      .carousel-inner img {
        height: 200px;
        min-width: 100%;
      }
      .carousel-caption {
        width: 80%;
        p {
          display: none;
        }
        h1 {
          font-size: 20px;
          a {
            font-size: 20px;
          }
        }
      }
    }


  }




}

.bodycms_onecol .carousel {
  min-height: 600px;
  background-size: cover;
  margin: 0 !important;
  //background: url('../../../img/header.gif');
  background: white;

  padding: 0 !important;

  .carousel-item {
    height: 600px;
    position: relative;
  }

  .carousel-inner img {
    object-fit: cover;
    height: 600px;
    min-width: 100%;
  }
  .carousel-caption {
    width: 65%;
    text-align: left;
    color: white;
    left: 5%;
    bottom: 20%;

    p {
      color: white;
      font-family: "Brioni Sans Light";
      font-size: 24px;
      font-weight: 300;
      text-shadow: none;
      background: rgba(35, 31, 32, .7);
      display: table-cell;
      padding: 10px;
    }

    a {
      color: white;
      text-decoration: none;
    }

    h1 {
      font-family: "Brioni Light";
      color: white;
      font-size: 48px;
      font-weight: 300;
      text-shadow: none;
      padding: 10px;
      display: inline-block;
      background: rgba(35, 31, 32, .7);
      margin-bottom : 0px !important;

      a {
        color: white;
        text-decoration: none;
      }
    }
  }


  @media (max-width: 1200px) {
    min-height: 500px !important;
    max-height: 500px !important;
    .carousel-item {
      height: 500px;
      position: relative;
    }
    .carousel-inner img {
      height: 500px;
      min-width: 100%;
    }
    .carousel-caption {
      width: 70%;
      p {
        font-size: 20px;
        font-weight: 300;
      }
      h1 {
        font-size: 36px;
        a {
          font-size: 36px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    min-height: 350px !important;
    max-height: 350px !important;
    .carousel-item {
      height: 350px;
      position: relative;
    }
    .carousel-inner img {
      height: 350px;
      min-width: 100%;
    }
    .carousel-caption {
      width: 70%;
      p {
        display: none;
      }
      h1 {
        font-size: 28px;
        a {
          font-size: 28px;
        }
      }
    }
  }
  @media (max-width: 576px) {
    min-height: 200px !important;
    max-height: 200px !important;
    .carousel-item {
      height: 200px;
      position: relative;
    }
    .carousel-inner img {
      height: 200px;
      min-width: 100%;
    }
    .carousel-caption {
      width: 80%;
      p {
        display: none;
      }
      h1 {
        font-size: 20px;
        a {
          font-size: 20px;
        }
      }
    }
  }


}

.skin_fpz .carousel {
  .carousel-caption {
    font-family: Raleway !important;
    font-weight: 300;
    background: transparent !important;
    text-align: left;
    color: white;
    left: 15%;
    bottom: 10%;
    p {
      font-family: Raleway !important;
      background: transparent !important;
      color: white;
      font-size: 32px;
      background: transparent !important;
      width: 320px;
    }

    a {
      color: white;
      text-decoration: none;
    }

    h1 {
      font-family: Raleway !important;
      text-transform: uppercase;
      background: transparent !important;
      color: white;
      font-size: 64px;
      padding: 10px;
      width: 320px;

      a {
        color: white;
        text-decoration: none;
      }
    }
    h1::after {
      display: block;
      height: 20px;
      width: 100px;
      content: "";
      border-bottom: 2px solid theme-color("primary") !important;
    }

    @media (max-width: map-get($grid-breakpoints, xl)) {
      left: 10%;
      bottom: 5%;
      p { font-size: 28px; }
      h1 {
        font-size: 48px;
        a { font-size: 48px; }
      }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      left: 5%;
      bottom: 5%;
      p { display: none; }
      h1 {
        font-size: 40px;
        a { font-size: 32px; }
      }
    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      left: 3%;
      bottom: 3%;
      p { display: none; }
      h1 {
        width: 200px;
        font-size: 32px;
        a { font-size: 32px; }
      }
    }
  }
}


.skin_fipu .carousel {


  @media (max-width: map-get($grid-breakpoints, xl)) {
    min-height: 500px !important;
    max-height: 500px !important;
    .carousel-item {
      height: 500px;
    }
    .carousel-inner img {
      height: 500px;
    }
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    min-height: 450px !important;
    max-height: 450px !important;
    .carousel-item {
      height: 450px;
    }
    .carousel-inner img {
      height: 450px;
    }

  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    min-height: 400px !important;
    max-height: 400px !important;
    .carousel-item {
      height: 400px;
    }
    .carousel-inner img {
      height: 400px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, xs)) {
    min-height: 350px !important;
    max-height: 350px !important;
    .carousel-item {
      height: 350px;
    }
    .carousel-inner img {
      height: 350px;
    }
  }


  .carousel-caption {
    background: rgba(0,0,0,0.7) !important;
    text-align: left;
    color: white;
    left: 15%;
    bottom: 10%;
    width: 500px;
    padding: 40px;
    p {
      color: white;
      font-size: 24px;
      background: transparent !important;
      width: 320px;
      border-left: 2px solid theme-color("primary") !important;
      padding: 0;
      padding-left: 20px;
      display: block;
      margin: 20px 0;
    }
    p:empty {
      display: none;
    }
    .link_button {
      display: block !important;
      a {
        font-size: 24px;
        padding: 10px 20px;
        border: 1px solid white;
        @extend .rounded-pill;
        display: inline-block;
        margin: 10px 0;
        &:hover {
          background: rgba(255,255,255,0.3);
          transition-duration: 0.3s;
        }
      }
    }

    a {
      color: white;
      text-decoration: none;
    }

    h1 {
      text-transform: uppercase;
      font-weight: 900;
      background: transparent !important;
      color: white;
      font-size: 48px !important;
      padding: 0;
      a {
        color: white;
        text-decoration: none;
      }
    }
    @media (max-width: map-get($grid-breakpoints, xl)) {
      width: 450px;
      left: 10%;
      bottom: 5%;
      p { font-size: 22px; }
      h1, h1 a { font-size: 44px !important;}
      .link_button a { font-size: 22px; }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 400px;
      left: 5%;
      bottom: 5%;
      p { font-size: 20px; }
      h1, h1 a { font-size: 40px !important;}
      .link_button a { font-size: 20px; }

    }
    @media (max-width: map-get($grid-breakpoints, sm)) {
      p {  font-size: 18px; }
      h1, h1 a { font-size: 36px !important;}
      .link_button a { font-size: 18px; }
      width: 350px;
      left: 3%;
      bottom: 3%;
      padding: 30px;
    }
    @media (max-width: map-get($grid-breakpoints, xs)) {
      p {  font-size: 18px; }
      h1, h1 a { font-size: 32px !important;}
      .link_button a { font-size: 16px; }
      width: 320px;
      left: 3%;
      bottom: 3%;
      padding: 20px;
    }

  }
}


.portlet_djelatnici {
  .staff-row .table th, .staff-row .table td {
    border-top: 1px solid #f7f7f7;
    padding-left: 0;
  }
  .staff-row {
    margin: 0 !important;
  }
  h4 {
    font-family: "Brioni Sans Light";
  }
  .person {
    margin-bottom: 5px;
    color: #231f20;
    font-weight: 600;
  }
  .fa-envelope, .fa-home, .fa-phone {
    color: #bbbbbb;
  }

  .fullscreen {
    @media (max-width: map-get($grid-breakpoints, md)) {
      display: none;
    }

  }
  .mobile {
    @media (min-width: map-get($grid-breakpoints, md)) {
      display:none;
    }

  }
  .ui-tabs {
    margin: 0;
    padding: 1px;
  }

}

.portlet_predmet_info {
    padding-left: 30px;
    p, li {
        font-size: 18px;
    }
    .col-md-3 {
      @media (min-width: 768px) {
        text-align: right;
      }
      p {
          color: #6a6a6a;
      }
    }
    .opterecenje_info {
        font-size: 14px;
        color: #6a6a6a;
    }
    a {
      color: theme-color("primary") !important;
    }
    .cms_module_title {
      display: none;
    }
}

.portlet_news_show_headlines {

  .card {
    background: #f9f9f9;
    position: relative;
    .card-body {
      height: 145px;
    }
    .card-footer {
      background: transparent;
      border: none;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      color: gray;
    }
  }
  .cover_link {
    transition-duration: 0.5s;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .cover_link:hover {
    background: rgba(0,0,0,0.06);
  }
}

.portlet_news_show_headlines a, .portlet_news_show_headlines a:hover {
    color: #231f20;
    text-decoration: none;
}


#cms_area_middle .portlet_news {
  //background-color: red;
  .card {
    margin-bottom: 30px !important;
    .header_flex {
      display: flex;
      flex-direction: row;
      &>div:first-child {
        flex: 1;
      }
      @media (max-width: map-get($grid-breakpoints, md)) {
        flex-direction: column;
      }

    }
  }
  .cms_module_title {
    margin-top: 10px;
    font-weight: 600;
    //font-size: $font-size-h1;
    //color: $text-color-dark !important;
  }
  h1 {
    font-size: 24px !important;
  }
  .news_options {
    padding: 0 !important;
  }

  .news_lead_small {
    word-break: break-word;
  }

  .news_article {
    .news_pub_date {
      padding-top:6px;
      width: 100%;
      & > time, div {
        float: right;
      }
    }
    .news_edit_date {
      line-height: 1;
      margin-right: -5px;
    }
    .news_article_left_col {
      float: left;
      margin-top: 6px;
      margin-right: 6px;
    }
    .news_article_title {
      position: relative;
      width: 100%;
      .news_title {
       h1 {
         font-size: 24px ;
         a {
           font-size: 24px ;
         }
       }
       h3 {
         font-size: 20px ;
         a {
           font-size: 20px ;
         }
       }
      }
    }
  }
}

#cms_area_onecol_white h1 {
    padding: 20px;
}

#cms_area_white_left .portlet_content {
  .col-lg-6 {
      min-height: 320px;
      overflow: hidden;
  }
  .col-md-12 {
    margin-bottom: 15px;
    overflow: hidden;
    }
  h2 {
    font-size: 21px;
    font-weight: bold;
    font-family: "Brioni Light";
    background-color: #3a3a3a;
    color: #ffffff;
    margin: 0;
    padding-top: 47px;
    padding-left: 20px;
    padding-right: 20px;
  }
p {
   background-color: #3a3a3a;
   color: #ffffff;
   margin: 0;
   padding-left: 20px;
   padding-right: 20px;
   padding-bottom: 20px;
  }

}


#cms_area_white_right .portlet_content {
  .col-lg-6 {
    //padding: 0;
    height: 320px;
    overflow: hidden;
  }
  .col-md-12 {
    //padding: 0 15px;
    height: 320px;
    overflow: hidden;
  }

  .col-lg-6:first-child {
    padding-right:0;
    @media (max-width: 991px) {
      padding-right: 15px;
    }
  }
  .col-lg-6:nth-child(2) {
    padding-left:0;
    @media (max-width: 991px) {
      padding-left: 15px;
    }
  }

  img {
    //width: 100%;
    object-fit: fill;
    height: 100%;
  }
  h2 {
    font-size: 21px;
    font-weight: bold;
    font-family: "Brioni Light";
    background-color: #3a3a3a;
    color: #ffffff;
    margin: 0;
    padding-top: 47px;
    //padding-left: 20px;
    padding-left: 15px;
  }
p {
   background-color: #3a3a3a;
   color: #ffffff;
   margin: 0;
   padding-left: 20px;
   padding-right: 20px;
   padding-bottom: 20px;

  }

}

#area_onecol_white .statistics_div {
    padding-bottom: 20px;
    margin-bottom: 10px;
    height: 100%;
  h2 {
      font-size: 62px;
      color: #9d0a0e;
      font-weight: bold;
      font-family: "Brioni Light";
      background-color: #f7f7f7;
      margin: 0;
      padding-top: 47px;
      height: 121px;
    }
  p {
     background-color: #f7f7f7;
     margin: 0;
     //padding-bottom: 55px;
     padding-left: 20px;
     padding-right: 20px;
     height: 113px;
    }
}


.footer {
  //min-height: 400px;
  background: #3a3a3a;
  color: white;
  position: relative;
  bottom: 0;
  a {
    color: white;
  }
  .footer_container > .row > div {
    padding-top: 30px;
    padding-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .footer-logo {
    height: 90px;
    width: auto !important;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      height: 75px;
    }
    @media (max-width: map-get($grid-breakpoints, lg)) {
      height: 90px;
    }
    @media (max-width: map-get($grid-breakpoints, md)) {
      height: 75px;
    }

  }
  .last-col {
    text-align: right;
    @media (max-width: 880px) {
      text-align: center;
      }
    .footer_caption a {
    padding-right: 10px;
    }
  }

  .footer_social {
    display: flex;
    flex-direction: row;
    p {
      display: flex;
    }
    a {
      height:45px;
      width: 45px;
      background: white;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
    }
  }
}
.footer_bottom {
    width: 100%;
    min-height: 50px;
    background: #231f20;
    //position: absolute;
    //bottom: 0;
    margin-left: 0;
    color: white;
    .footer_bottom_container {
      padding-top: 15px;
      padding-bottom: 15px;
      a {
        float: right;
        color: white;
      }
    }
  }

  div.popup_menu {
    z-index: 999999999 !important;
  }

.cms_module {
  .ui-widget {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  .ui-tabs {
    .ui-tabs-nav {

      border: none !important;

      li {
        border: 1px solid $gray-200;
        padding: 0;
        margin: 1px 0 0 0;
        a {
          background-color: transparent !important;
          border: none !important;
          padding: 10px 15px;
          text-transform: uppercase;
          color: theme-color("primary") !important;

        }
        &.ui-state-default {
          background-color: white !important;
          &:hover {
            background-color: $gray-300 !important;
          }
        }
        &.ui-state-active {
          a {
            color: $gray-700 !important;
          }
          background-color: $gray-200 !important;
        }
      }
    }
  }



}

.breadcrumbs_div {
  min-height: 40px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
}

#breadcrumbs {
  ol.breadcrumb {
    background: white;
    padding: 8px 0;
    margin-left: -15px;
    margin-bottom: 0;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-left: 0px;
    }
      li::before {
      padding: 0 5px;
      color: #ccc;
      content: "/\00a0";
    }
    li:first-child::before {
      display:none;
    }
  }
}

.cookie-message-container {
  background: rgba(32,32,32,0.9);
  padding: 10px 20px;
  color: white;
}


.popup_upisi {
  .popup_upisi_link {
    display: block;
    position: absolute;
    top: 25%;
    left: 25%;
    height: 50%;
    width: 50%;
  }
}

.sidepanel .icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .accessibility {
    height: 44px;
    width: 44px;
    padding-left: 15px;
  }
  padding-bottom: 15px;
}

#accessibility_opener {
  display: inline-block;
  img {
    display: none
  }
  font-size: 0px;
  color: transparent;
  cursor: pointer;
  height: 44px;
  width: 44px;
  background: url('../../../icons/accessibility.png');
  background-size: cover;
  position: fixed;
  right: 1%;
  bottom: 50%;
  z-index: 1000;
  background-color: white;
  border-radius: 25px;
}


.accessibility_dialog {
  @extend .shadow;
  max-width: 320px;
  width: 320px !important;
  background: white;
  padding: 15px;
  border: none;
  font-family: inherit;
  z-index: 9999;
  .ui-dialog-title{
    text-transform: uppercase;
  }
  .ui-dialog-titlebar {
    border: none !important;
  }
  .ui-dialog-titlebar-close {
    @extend .rounded;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    border: none;
    background: theme-color("primary") url('../../../icons/close.png') center no-repeat;
    -webkit-background-size: 10px;
    background-size: 10px;
  }
  #accessibility_links {
    max-width: 280px;
    .textsize {
      .gornjilinkoviboxVelicina {
        padding: 10px;
        font-weight: 800;
      }
      .select_size_0 {
        font-size: 11px !important;
      }
      .select_size_1 {
        font-size: 13px !important;
      }
      .select_size_2 {
        font-size: 15px !important;
      }
    }
    .highcontrast {
      .highcontrast_label {
        float: left;
        width: 90%;
      }
      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
    .simplelayout {
      .simplelayout_label {
        float: left;
        width: 90%;
      }
      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
  }
}


//Accessibility text size:
body.textsize-big {
  font-size: 20px;

  .accessibility_links, .ui-dialog-title {
    font-size: 16px !important;
    }
  .login_icon .login_link .fa-unlock-alt {
    font-size: 16px;
  }

  .top_link_row > .top_link{
    .fa {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
  }

  #cms_area_horizontal_nav .portlet_nav a {
    font-size: 20px;
  }
  #cms_area_right, #cms_area_left .portlet_nav .nav-element {
    .nav_link {
      font-size: 22px !important;
      padding: 8px;
    }
    li > ul li {
      padding-top: 0;
    }
    ul > li > a {
      font-size: 22px !important;
      padding: 8px;
    }
  }

  .cms_module_title {
    font-size: 24px;
  }

  .portlet_news {
    .news_article_title h1 {
      .news_title_truncateable {
        font-size: 24px !important;
      }
    }
  }
  .sidepanel .language span.w3c-lang-name {
    font-size: 16px;
    padding: 4px;
  }
  .sidepanel .login a {
    font-size: 20px;
    top: 6px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption h1 {
    font-size: 56px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p {
    font-size: 30px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p a{
    font-size: 30px !important;
  }

  #cms_area_middle .portlet_news_show_headlines h5, #cms_area_onecol_white .portlet_news_show_headlines h5 {
    font-size: 1.70rem;
  }

  #cms_area_white_left .portlet_content h2 {
    font-size: 2rem;
  }

}//end of .textsize-big
//END accessibility text size


//accessibility simplelayout
body.simplelayout {
  .carousel {
    display: none;
  }
  #cms_area_middle_right {
    .portlet_news_show_headlines {
      script {
        display: none;
      }
    }
  }
}
//END accessibility simplelayout

//accessibility contrast
body.highcontrast-normal {
  color: black !important;
  a {
    color: darken($primary, 50%);
  }
  .header_columns {
    color: black;
  }
  .top_link_row > .top_link a {
    color: black;
  }
  .accessibility_dialog {
    color: black;
    .ui-dialog-title{
      color: black;
    }
    .ui-dialog-titlebar-close {
      color: black;
    }
  }
  .horizontal-nav-row {
    color: black !important;
  }
  .portlet_nav {
    color: black !important;
    .nav > li > a {
      color: black !important;
    }
    .nav > li > ul > li a {
      color: black !important;
    }
  }
  .header-search_input {
    border: 1px solid $border-color !important;
    color: black;
    background-color: transparent !important;
  }
  .cms_module_title {
    color: black !important;
  }
  .portlet_news_show_headlines {
    .carousel-caption {
      color: black !important;
      .truncate-to-visible {
        color: black !important;
      }
    }
  }
  .carousel-caption {
    color: black !important;
    .truncate-to-visible {
      color: black !important;
    }
  }
  .item .carousel-caption .title a {
    color: black !important;
  }
  .item .carousel-caption h1 a {
    color: black !important;
  }
  div.portlet_repository span.cms_info_about_authortime {
    color: black !important;
  }

  .middle-left-col .portlet_content .main_page_upper_links a, .middle-left-col .portlet_content .main_page_upper_links a:focus, .middle-left-col .portlet_content .main_page_upper_links a:active, .middle-left-col .portlet_content .main_page_upper_links a:hover, .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: black !important;
    font-weight: 800;
  }
  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: black !important;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: black !important;
  }
  #footer_container {
    background-color: $border-color;
  }
  #footer_container #footer_header {
    color: black !important;
  }
  #footer_container #footer_header strong, #footer_container #footer_row h3 {
    color: black !important;
    font-weight: 800;
  }
  #footer_container #footer_row {
    color: black !important;
  }
  #footer_container #footer_row ul li a, #footer_container #footer_row ul li a:visited {
    color: black !important;
  }
  .mega-dropdown-menu {
    background: white !important;
  }
  .search-button, .lang_link {
    color: black !important;
  }
  .div_login img, .hamburger img {
   filter: invert(100%);
  }
  .mm-listview>li>a, .mm-listview>li>span {
    color: black !important;
  }
  #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:active, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:focus, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu  a:hover, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:visited {
     color: black !important;
  }
  .header .header-icons .search-separator .search-container input {
     border: 1px solid black;
     background-color: white !important;
     color: black !important;
  }
}
//END accessibility contrast

//accessibility inverted contrast
body.highcontrast-inverted {
  color: white !important;
  background-color: #777;
  a {
    color:  white !important;
  }
  .popup_menu {
    background-color: #777 !important;
  }

  .card {
    background-color: #333;
  }
    
  #area_onecol_white .statistics_div h2, #area_onecol_white .statistics_div p {
    color: white;
    background-color: #333;
  }
  .portlet_admin_traka {
    .dropdown-menu {
      background-color: #777 !important;
      > li > a {
        background-color: #777 !important;
      }
    }
  }
  .logo_div {
    img.normal {
      display: none;
    }
    img.white {
      display: block;
    }
  }
  .header_columns {
    color: white !important;
  }
  .fixed_header {
    background-color: #333 !important;
  }
  #area_onecol_white, #area_onecol_silver, .twocol_side {
    background-color: #777;
  }
  .news_show_headlines_render_articles .card {
    background-color: #333;
  }
  .above_header .header_logo {
    filter: invert(1);
  }
  .sidepanel {
    background-color: #333 !important;
  }
  .sidepanel .col-12.col-md-12.col-lg-8 .bg-light, .sidepanel .col-12.col-md-12.col-lg-4 {
    background-color: #333 !important;
  }
  .sidepanel .language .current_lang span.w3c-lang-name, .sidepanel .language span.w3c-lang-name {
    color: white !important;
    border: 1px solid white !important;
  }
  .top_link_row > .top_link a {
    color: white !important;
  }
  .ui-widget-content {
    background-color: #777 !important;
  }
  .accessibility_dialog {
    span {
      color: white !important;
      font-weight: 800;
    }
    color: white !important;
    .ui-dialog-title{
      color: white !important;
    }
    .ui-dialog-titlebar-close {
      //color: white !important;
    }
  }
  .horizontal-nav-row {
    color: white !important;
  }
  .portlet_nav {
    color: white !important;
    background-color: #333;

    .nav > li > a {
      color: white !important;
    }
    .nav > li > ul > li a {
      color: white !important;
    }
    .nav {
      background-color: transparent !important;
    }
  }
  .nav ul li, .nav ul li:hover {
    background-color: #333 !important;
  }

  #cms_area_left .portlet_nav .nav_link:hover, #cms_area_left .portlet_nav .collapse-link:hover,  #cms_area_left .portlet_nav .nav-element .collapse-link:hover, #cms_area_left .portlet_nav .nav-element:hover {
    background-color: #333 !important;
  }
  div.repository .open, div.repository .closed {
    background-color: #333;
  }
  div.repository image {
    filter: invert(1);
  }
  div.repository .closed div.arrow, div.repository div.open div.arrow {
    color: white;
  }
  div.portlet_repository div.content {
    color: lightgrey;
  }
  #v1tooltip_div {
    background-color: #333;
  }
  .portlet_subscription .subscicons, .portlet_subscription .subs_module {
    color: white !important;
  }
  .portlet_predmet_info .col-md-3 p, .portlet_predmet_info .opterecenje_info, .portlet_predmet_info div em {
    color: white !important;
  }
  .staff_group, .portlet_djelatnici .person {
    color: white;
  }
  .header-search_input {
    border: 2px solid white !important;
    color: white !important;
    background-color: transparent !important;
  }
  .cms_module_title {
    color: white !important;
  }
  .portlet_news_show_headlines {
    .carousel-caption {
      color: white !important;
      .truncate-to-visible {
        color: white !important;
      }
    }
  }
  .carousel-caption {
    color: white !important;
    .truncate-to-visible {
      color: white !important;
    }
  }
  .carousel {
    border-color:  #777 !important;
    .item {
      background-color: #777 !important;
    }
  }
  input, select {
    //border: 2px solid @brand-primary;
    //background-color: @brand-primary;
    color:  darken($border-color, 60%);
  }
  tr {
    background-color: darken($border-color, 60%)  !important;
    color: white;
  }
  tr:hover {
    background-color: darken($border-color, 60%)  !important;
    color: white !important;
    a, a:hover, a:active, a:focus {
      color: white !important;
      font-weight: 800 !important;
    }
  }
  tr:nth-of-type(2n+1) {
    background-color: darken($border-color, 30%)  !important;
    color: white;
  }
  tr:nth-of-type(2n+1):hover {
    background-color: darken($border-color, 30%)  !important;
    color: white;
    a, a:hover, a:active, a:focus {
      font-weight: 800 !important;
    }
  }
  .predmetispis_lighter {
    color: white;
  }
  .predmetispis_lighter:hover {
    font-weight: bold;
  }
  .item .carousel-caption .title a {
    color: white !important;
  }
  .item .carousel-caption h1 a {
    color: white !important;
  }
  div.portlet_repository span.cms_info_about_authortime {
    color: white !important;
  }

  .middle-left-col .portlet_content .main_page_upper_links a, .middle-left-col .portlet_content .main_page_upper_links a:focus, .middle-left-col .portlet_content .main_page_upper_links a:active, .middle-left-col .portlet_content .main_page_upper_links a:hover, .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: white !important;
    font-weight: 800;
    background-color: #333 !important;
  }
  .portlet_news_show_headlines .news_headlines .article .thumbnail {
    background-color: transparent !important;
  }
  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: white !important;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: white !important;
  }
  #footer_container {
    background-color: transparent;
  }
  #footer_container #footer_header {
    color: white !important;
  }
  #footer_container #footer_header strong, #footer_container #footer_row h3 {
    color: white !important;
    font-weight: 800;
  }
  #footer_container #footer_row {
    color: white !important;
  }
  #footer_container #footer_row ul li a, #footer_container #footer_row ul li a:visited {
    color: white !important;
  }

  .gray, .white, .blue, .footer, .white-layer, .mega-dropdown-menu, .gradient, #mmenu_init {
      background-color: #333 !important;
  }

}
//END accessibility inverted contrast
.dvorana {
  margin-right:30px !important;
}
